import React from "react";

const navigation = {
  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/Eye2Gene/?ref=page_internal",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Twitter",
      href: "https://twitter.com/eye2gene",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
    {
      name: "GitHub",
      href: "https://github.com/eye2gene",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ],
};

const Footer = () => (
  <footer className="dark:bg-apple-black" aria-labelledby="footer-heading">
    <h2 id="footer-heading" className="sr-only">
      Footer
    </h2>
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <div className="xl:grid xl:grid-cols-2">
        <div className="mt-8 xl:mt-0">
          <p className="mt-4 text-xl dark:text-gray-300">
            UCL Institute of Ophthalmology
          </p>
          <p className="text-xl dark:text-gray-300">
            11-43 Bath Street, London, EC1V 9EL
          </p>
        </div>
        <div className="mt-8 xl:mt-0">
          <p className="mt-4 text-xl dark:text-gray-300">
            We are thankful to our funders and our partners:
          </p>
          <div className="flex justify-between py-2 px-1 bg-white mt-2 ">
            <a
              href="https://moorfieldseyecharity.org.uk/impact-stories/career-development-awards-make-all-the-difference"
              target="_blank"
              rel="noopener noreferrer"
              className="mr-2"
            >
              <img
                src="images/moorfields-charity.png"
                alt=""
                className="funder"
              />
            </a>
            <a
              href="https://moorfieldsbrc.nihr.ac.uk/"
              target="_blank"
              rel="noopener noreferrer"
              className="mr-2"
            >
              <img
                src="https://d33wubrfki0l68.cloudfront.net/99ed7658808d8334931e41ed5e8ec9f33e656a3f/120d5/images/moorfields-brc-logo.jpg"
                alt="Moorfields Charity"
                className="funder"
              />
            </a>
            <a
              href="https://www.nhs.uk/"
              className="bg-white mr-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="images/NHS.png" alt="NHS" className="funder" />
            </a>
            <a
              href="https://www.moorfields.nhs.uk/"
              className="bg-white mr-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="images/moorfields.png"
                alt="Moorfields"
                className="funder"
              />
            </a>
            <a
              href="https://www.ucl.ac.uk/ioo/"
              className="bg-white"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="images/ucl.png" alt="UCL" className="funder" />
            </a>
            <a
              href="https://retinauk.org.uk/about/"
              className="bg-white mx-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="images/retina-uk.png" alt="UCL" className="funder" />
            </a>
            <a
              href="https://stargardtsconnected.org.uk/"
              className="bg-white mx-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="images/stargardt.png" alt="UCL" className="funder" />
            </a>
          </div>
          <div className="mt-2">
            <img src="images/funders.png" alt="funders" className="funder" />
          </div>
        </div>
      </div>
      <div className=" pt-8 lg:flex lg:items-center lg:justify-between xl:mt-0">
        <div>
          <h3 className="text-sm font-semibold dark:text-gray-300 tracking-wider uppercase">
            Website designed by Nathaniel Kabiri and
          </h3>
          <h3 className="text-sm font-semibold dark:text-gray-300 tracking-wider uppercase">
            Coded by Prashant Verma and Nicoleta Calin
          </h3>
          <p className="text-base dark:text-gray-300">
            With thanks to our Patient Advisory Group for their insight and
            ideas
          </p>
        </div>
      </div>

      <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
        <div className="flex space-x-6 md:order-2">
          {navigation.social.map((item) => (
            <a
              target="_blank"
              rel="noopener noreferrer"
              key={item.name}
              href={item.href}
              className="dark:text-gray-400 hover:text-gray-300"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-8 w-8" aria-hidden="true" />
            </a>
          ))}
        </div>
        <p className="mt-8 text-xl dark:text-gray-400 md:mt-0 md:order-1">
          2023 Eye2Gene&trade;. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
);

export default Footer;
