import React from "react";

const Publications = () => {
  const Articles = [
      {
      id: 1,
      title:
        "Quantification of Fundus Autofluorescence Features in a Molecularly Characterized Cohort of More Than 3000 Inherited Retinal Disease Patients from the United Kingdom",
      content:
        "William Woof, Thales A C de Guimarães, Saoud Al-Khuzaei, Malena Daich Varela, Sagnik Sen, Pallavi Bagga, Bernardo Mendes, Mital Shah, Paula Burke, David Parry, Siying Lin, Gunjan Naik, Biraja Ghoshal, Bart Liefers, Dun Jack Fu, Michalis Georgiou, Quang Nguyen, Alan Sousa da Silva, Yichen Liu, Yu Fujinami-Yokokawa, Nathaniel Kabiri, Dayyanah Sumodhee, Praveen Patel, Jennifer Furman, Ismail Moghul, Juliana Sallum, Samantha R De Silva, Birgit Lorenz, Frank Holz, Kaoru Fujinami, Andrew R Webster, Omar Mahroo, Susan M Downes, Savita Madhusuhan, Konstantinos Balaskas, Michel Michaelides, Nikolas Pontikos",
      href: "https://pubmed.ncbi.nlm.nih.gov/38585957/",
    },
    {
      id: 2,
      title:
        "Eye2Gene: prediction of causal inherited retinal disease gene from multimodal imaging using deep-learning",
      content:
        "Nikolas Pontikos, William Woof, Advaith Veturi, Behnam Javanmardi, Miguel Ibarra-Arellano, Alexander Hustinx, Ismail Moghul, Yichen Liu, Kristina Heß, Michalis Georgiou, Maximilian Pfau, Mital Shah, Jing Yu, Saoud Al-Khuzaei, Siegfried Wagner, Malena Daich Varela, Thales Cabral de Guimarães, Sagnik Sen, Nathaniel Kabiri, Quang Nguyen, Jennifer Furman, Bart Liefers, Aaron Lee, Samantha De Silva, Caio Texeira, Fabiana Motta, Yu Fujinami-Yokokawa, Gavin Arno, Kaoru Fujinami, Juliana Sallum, Savita Madhusudhan, Susan Downes, Frank Holz, Konstantinos Balaskas, Andrew Webster, Omar Mahroo, Peter Krawitz, Michel Michaelides",
      href: "https://www.researchsquare.com/article/rs-2110140/v1",
    },
    {
      id: 3,
      title:
        "Can artificial intelligence accelerate the diagnosis of inherited retinal diseases? Protocol for a data-only retrospective cohort study (Eye2Gene)",
      content:
        "Quang Nguyen, William Woof, Nathaniel Kabiri, Sagnik Sen, Malena Daich Varela, Thales Antonio Cabral De Guimaraes, Mital Shah, Dayyanah Sumodhee, Ismail Moghul, Saoud Al-Khuzaei, Yichen Liu, Catherine Hollyhead, Bhavna Tailor, Loy Lobo, Carl Veal, Stephen Archer, Jennifer Furman, Gavin Arno, Manuel Gomes, Kaoru Fujinami, Savita Madhusudhan, Omar A Mahroo, Andrew R Webster, Konstantinos Balaskas, Susan M Downes, Michel Michaelides, Nikolas Pontikos",
      href: "https://bmjopen.bmj.com/content/13/3/e071043",
    },
    {
      id: 4,
      title:
        "SynthEye: Investigating the Impact of Synthetic Data on Artificial Intelligence-assisted Gene Diagnosis of Inherited Retinal Disease",
      content:
        "Yoga Advaith Veturi, William Woof, Teddy Lazebnik, Ismail Moghul, Peter Woodward-Court, Siegfried K. Wagner, Thales Antonio Cabral de Guimarães, Malena Daich Varela, Bart Liefers, Praveen J. Patel, Stephan Beck, Andrew R. Webster, Omar Mahroo, Pearse A. Keane, Michel Michaelides, Konstantinos Balaskas, Nikolas Pontikos",
      href: "https://www.sciencedirect.com/science/article/pii/S2666914522001476",
    },
  ];
  return (
    <section className="relative block w-full bg-center pt-40 pb-40">
      <div className="relative w-full mr-auto ml-auto px-4">
        <h2 className="mb-12 text-center text-3xl sm:text-5xl font-semibold dark:text-white text-apple-black leading-tight">
          Publications Work
          <small className="block text-slate-500 text-base mt-1.5 font-bold tracking-widest">
            Our peer-reviewed publications
          </small>
        </h2>
        <div className="flex flex-col items-center max-w-7xl  sm:mx-60 mx-4 md:mx-20 lg:mx-96">
          <div className="font-semibold dark:text-white text-apple-black sm:text-2xl  text-xl">
            See below for a list of our publications:
          </div>
          <ol className="flex flex-col items-center list-decimal sm:text-xl text-lg dark:text-white text-apple-black">
            {Articles?.map((article) => (
              <li key={article.id} className="rounded-lg shadow p-4 mb-4 ">
                <a
                  href={article.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-semibold mb-2 border-b-2 border-dashed sm:text-2xl  text-xl border-blue-500"
                >
                  {article.title}
                </a>
                <p className="sm:text-xl text-lg">{article.content}.</p>
              </li>
            ))}
          </ol>
        </div>
      </div>
    </section>
  );
};

export default Publications;
