import React, { Fragment } from "react";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { OpenButton, VisualOptions } from "./VisualOptions";
import Toggle from "../components/toggle";

const Navbar = () => {
  return (
    <>
      <Disclosure
        as="nav"
        className="bg-white dark:bg-apple-black fixed z-40 w-full"
      >
        {({ open }) => (
          <>
            <div className="max-w-full mx-auto px-4 sm:px-8 lg:px-8">
              <div className="flex items-center justify-between h-16">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="block dark:hidden lg:hidden h-9 w-auto"
                      src="logo_black.svg"
                      alt="logo"
                    />
                    <img
                      className="hidden dark:hidden lg:block h-8 w-auto"
                      src="logo_black.svg"
                      alt="logo"
                    />
                    <img
                      className="hidden dark:block lg:hidden h-9 w-auto"
                      src="logo_white.svg"
                      alt="logo"
                    />
                  </div>
                  <div className="lg:hidden lg:pt-0 lg:pb-0">
                    <div className="flex items-center px-5">
                      <div className="flex-shrink-0">
                        <div className="flex">
                          <Toggle />
                          <div className="ml-4">
                            <OpenButton />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="hidden lg:block sm:ml-6">
                    <div className="flex space-x-4">
                      {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}

                      <a
                        href="/#theEye"
                        className="text-gray-600 dark:text-white hover:bg-black hover:text-white px-3 py-2 rounded-md text-base lg:text-xl font-medium"
                      >
                        The Eye
                      </a>
                      <a
                        href="/#patient-journey"
                        className="text-gray-600 dark:text-white hover:bg-black hover:text-white px-3 py-2 rounded-md text-base lg:text-xl font-medium"
                      >
                        Patient Journey
                      </a>
                      <a
                        href="/#artificial-intelligence"
                        className="text-gray-600 dark:text-white hover:bg-black hover:text-white px-3 py-2 rounded-md text-base lg:text-xl font-medium"
                      >
                        Artificial Intelligence
                      </a>
                      <a
                        href="/#eye2gene"
                        className="text-gray-600 dark:text-white hover:bg-black hover:text-white px-3 py-2 rounded-md text-base lg:text-xl font-medium"
                      >
                        Eye2Gene Research
                      </a>
                      <a
                        href="/#our-story"
                        className="text-gray-600 dark:text-white hover:bg-black hover:text-white px-3 py-2 rounded-md text-base lg:text-xl font-medium"
                      >
                        The Team
                      </a>

                      <a
                        href="/#contact-us"
                        className="text-gray-600 dark:text-white hover:bg-black hover:text-white px-3 py-2 rounded-md text-base lg:text-xl font-medium"
                      >
                        Contact Us
                      </a>

                      <a
                        href="/publications"
                        className="text-gray-600 dark:text-white hover:bg-black hover:text-white px-3 py-2 rounded-md text-base lg:text-xl font-medium"
                      >
                        Publications
                      </a>

                      <div className="hidden lg:ml-6 lg:block">
                        <div className="flex">
                          <Toggle />
                          <div className="ml-4 ">
                            <OpenButton />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="-mr-2 flex lg:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center gap-x-6 bg-green-500 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
              <p className="text-xl lg:text-xl leading-6 text-white">
                <a
                  href="https://www.researchsquare.com/article/rs-2110140/v1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong className="font-semibold">
                    Preprint under review with Nature journal
                  </strong>
                  <svg
                    viewBox="0 0 2 2"
                    className="mx-2 inline h-1 w-1 fill-current"
                    aria-hidden="true"
                  >
                    <circle cx={1} cy={1} r={1} />
                  </svg>
                  Read more&nbsp;<span aria-hidden="true">&rarr;</span>
                </a>
              </p>
              <div className="flex flex-1 justify-end"></div>
            </div>

            <Disclosure.Panel className="lg:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                <Disclosure.Button
                  as="a"
                  href="/#theEye"
                  className="text-gray-600 dark:text-white hover:bg-black hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  The eye
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="/#patient-journey"
                  className="text-gray-600 dark:text-white hover:bg-black hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Patient journey
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="/#artificial-intelligence"
                  className="text-gray-600 dark:text-white hover:bg-black hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Artificial intelligence
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="/#eye2gene"
                  className="text-gray-600 dark:text-white hover:bg-black hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Eye2Gene
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="/#our-story"
                  className="text-gray-600 dark:text-white hover:bg-black hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  The Team
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="/#contact-us"
                  className="text-gray-600 dark:text-white hover:bg-black hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Contact us
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="/publications"
                  className="text-gray-600 dark:text-white hover:bg-black hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Publications
                </Disclosure.Button>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <VisualOptions />
    </>
  );
};

export default Navbar;
