import React from "react";
import Header from "./components/Header";
import TheEye from "./components/TheEye";
import PatientJourney from "./components/PatientJourney";
import AI from "./components/AI";
import Eye2Gene from "./components/Eye2Gene";
import OurStory from "./components/OurStory";
import Contact from "./components/Contact";

const App = () => {
  return (
    <>
      <div className="bg-white">
        <div className="relative overflow-visible">
          <Header />
          <TheEye />
          <PatientJourney />
          <AI />
          <Eye2Gene />
          <OurStory />
          <Contact />
        </div>
      </div>
    </>
  );
};

export default App;
