import React from "react";

const TheEye = () => (
  <div className="bg-black dark:bg-black">
    <div
      id="carouselDarkVariant"
      className="carousel slide carousel-fade mt-2 relative"
      data-bs-ride="carousel"
    >
      <div className="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
        <button
          data-bs-target="#carouselDarkVariant"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          data-bs-target="#carouselDarkVariant"
          data-bs-slide-to="1"
          aria-label="Slide 1"
        ></button>
        <button
          data-bs-target="#carouselDarkVariant"
          data-bs-slide-to="2"
          aria-label="Slide 1"
        ></button>
      </div>

      <div className="carousel-inner relative w-full overflow-hidden">
        <div className="carousel-item active relative float-left w-full">
          <img
            src="images/chromosome_background.jpg"
            className="object-contain sm:object-cover h-screen w-full"
            alt="gene"
          />
          <div className="carousel-caption block md:block absolute text-center">
            <p className="text-xl sm:text-4xl bg-white dark:bg-black rounded-md text-black dark:text-white sm:leading-[3rem] leading-0">
              The human body is like a complex machine. The instructions for
              running it are stored in your genes. Genes are blueprints that
              tell the body how to function. Everyone inherits a mixture of
              their genes from each of their parents, which is why we look
              similar to them.
            </p>
          </div>
        </div>

        <div className="carousel-item relative float-left w-full">
          <img
            src="images/chromosome_background_2.jpg"
            className="object-contain sm:object-cover h-screen w-full"
            alt=""
          />
          <div className="carousel-caption block md:block absolute text-center">
            <p className="text-xl sm:text-4xl bg-white dark:bg-black rounded-md  text-black dark:text-white sm:leading-[3rem] leading-0">
              Genes can "mutate", or become damaged. Each gene stores
              information about how a specific body part should work. When a
              gene mutates, this information is altered, so that body part does
              not function as usual. This is called inherited disease, as we can
              inherit faulty genes from our parents
            </p>
          </div>
        </div>

        <div className="carousel-item relative float-left w-full">
          <img
            src="images/eye_background.jpg"
            className="object-contain sm:object-cover h-screen w-full"
            alt=""
          />
          <div className="carousel-caption block md:block absolute text-center">
            <p className="text-xl sm:text-4xl bg-white dark:bg-black rounded-md  text-black dark:text-white sm:leading-[3rem] leading-0">
              We see the world around us using our eyes. The back of the eye is
              called the retina. It converts light into information for the
              brain to understand. If the retina does not work correctly, your
              vision will be impaired. Many diseases can affect the retina,
              including some caused by faulty genes. These are called inherited
              retinal disease (IRD). They are one of the leading causes of
              blindness in children and young adults.
            </p>
          </div>
        </div>
      </div>

      <button
        className="carousel-control-prev text-white absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
        type="button"
        data-bs-target="#carouselDarkVariant"
        data-bs-slide="prev"
      >
        <span
          className="carousel-control-prev-icon w-16 h-16 sm:w-20 sm:h-20 bg-black rounded-full text-white inline-block bg-no-repeat"
          aria-hidden="true"
        ></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next absolute  top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
        type="button"
        data-bs-target="#carouselDarkVariant"
        data-bs-slide="next"
      >
        <span
          className="carousel-control-next-icon w-16 h-16 bg-black rounded-full sm:w-20 sm:h-20 text-white inline-block bg-no-repeat"
          aria-hidden="true"
        ></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  </div>
);

export default TheEye;
