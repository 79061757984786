import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { CheckIcon } from "@heroicons/react/outline";
import { XCircleIcon, XIcon } from "@heroicons/react/solid";

// import ReCAPTCHA from "react-google-recaptcha";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const Contact = () => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  // const recaptchaRef = useRef();
  // const [buttonDisabled, setButtonDisabled] = useState(true);

  // const RECAPTCHA_KEY = "6LcnDKIfAAAAABK4mDjGZyjsCnLL9EccAs3-BXjy";

  const {
    register,
    handleSubmit,
    reset,

    formState: { errors },
  } = useForm();

  const onSubmit = (data, e) => {
    e.preventDefault();
    // const form = e.target;
    // const recaptchaValue = recaptchaRef.current.getValue();

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "eye2gene",
        // "g-recaptcha-response": recaptchaValue,
        ...data,
      }),
    })
      .then((response) => {
        if (response) {
          setShowSuccessMessage(true);
          reset();
          // window.grecaptcha.reset();
        }
      })
      .catch((error) => {
        if (error) {
          setErrorMessage(true);
        }
      });
  };

  return (
    <div id="contact-us" className="bg-white dark:bg-black sm:pt-40 pt-20">
      <main>
        <div className="py-16 bg-white dark:bg-black">
          <div className="max-w-md mx-auto pl-4 pr-4 sm:max-w-lg sm:px-6 lg:max-w-7xl lg:px-8">
            <h1 className="text-4xl leading-10 font-bold tracking-tight text-black dark:text-white text-center text-5xl sm:text-8xl sm:leading-none lg:text-8xl">
              Get in touch
            </h1>
            <p className="mt-9 sm:text-center  mx-auto sm:text-3xl text-2xl  text-black dark:text-white">
              If you are interested the Eye2Gene research, or if you or your
              children are IRD patients, please get in touch. We hold a patient
              meeting via Zoom every few months to discuss our progress. You can
              share suggestions on how to improve the project. Your feedback
              will influence the future of how we diagnose IRD.
            </p>
          </div>
        </div>

        {/* Contact Section */}
        <div className="relative bg-white dark:bg-black">
          <div className="relative px-4 sm:pb-24 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto ">
            <div className="pb-10 lg:pl-24">
              <div className="max-w-md mx-auto sm:max-w-6xl lg:mx-0">
                <form
                  action="/"
                  method="POST"
                  onSubmit={handleSubmit(onSubmit)}
                  data-netlify-recaptcha="true"
                  data-netlify="true"
                  name="react-validation-form"
                  id="contactForm"
                  className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
                >
                  <input type="hidden" name="form-name" value="eye2gene" />
                  <input type="hidden" name="project" value="eye2gene" />
                  <p hidden>
                    <label>
                      Don&apos;t fill this out:{" "}
                      <input name="bot-field" type="text" />
                    </label>
                  </p>

                  <div>
                    <label
                      htmlFor="firstName"
                      className="block text-xl sm:text-2xl font-medium text-black dark:text-white"
                    >
                      First name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        autoComplete="given-name"
                        className="block w-full shadow-sm sm:text-sm focus:ring-grape-500 focus:border-grape-500 border-black dark:border-white rounded-md"
                        {...register("firstName", {
                          required: "Please enter your first name.",
                        })}
                      />
                    </div>
                    {errors.firstName && (
                      <p className="text-red-500 text-sm italic">
                        {errors.firstName.message}
                      </p>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="lastName"
                      className="block text-xl sm:text-2xl font-medium text-black dark:text-white"
                    >
                      Last name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        autoComplete="family-name"
                        className="block w-full shadow-sm sm:text-sm focus:ring-grape-500 focus:border-grape-500 border-black dark:border-white rounded-md"
                        {...register("lastName", {
                          required: "Please enter your last name.",
                        })}
                      />
                    </div>
                    {errors.lastName && (
                      <p className="text-red-500 text-sm italic">
                        {errors.lastName.message}
                      </p>
                    )}
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="email"
                      className="block text-xl sm:text-2xl font-medium text-black dark:text-white"
                    >
                      Email
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        className="block w-full shadow-sm sm:text-sm focus:ring-grape-500 focus:border-grape-500 border-black dark:border-white rounded-md"
                        {...register("email", {
                          required: "Please enter your email.",
                        })}
                      />
                    </div>
                    {errors.email && (
                      <p className="text-red-500 text-sm italic">
                        {errors.email.message}
                      </p>
                    )}
                  </div>

                  <div className="sm:col-span-2">
                    <div className="flex justify-between">
                      <label
                        htmlFor="howCanWeHelp"
                        className="block text-xl sm:text-2xl font-medium text-black dark:text-white"
                      >
                        Message
                      </label>
                      <span
                        id="howCanWeHelp-description"
                        className="text-base text-black dark:text-white"
                      >
                        Max. 500 characters
                      </span>
                    </div>
                    <div className="mt-1">
                      <textarea
                        id="howCanWeHelp"
                        name="message"
                        aria-describedby="howCanWeHelp-description"
                        rows={4}
                        className="block w-full shadow-sm sm:text-sm focus:ring-grape-500 focus:border-grape-500 border border-black dark:border-white rounded-md"
                        defaultValue={""}
                        {...register("message", {
                          required: "Please enter your message.",
                        })}
                      />
                    </div>
                    {errors.message && (
                      <p className="text-red-500 text-sm italic">
                        {errors.message.message}
                      </p>
                    )}
                  </div>
                  <div className="pt-8">
                    <div>
                      <h3 className="text-3xl leading-6 font-medium dark:text-white">
                        Tell us more about you
                      </h3>
                    </div>
                    <div className="mt-6">
                      <fieldset>
                        <div className="mt-4 space-y-4">
                          <div className="relative flex items-start">
                            <div className="flex items-center h-5">
                              <input
                                id="healthcare"
                                name="researcher"
                                type="checkbox"
                                className="focus:ring-green-500 h-4 h-4 sm:h-6 sm:w-6 text-green-600 border-gray-300 rounded"
                                {...register("researcher", {
                                  required: false,
                                })}
                              />
                            </div>
                            <label
                              htmlFor="healthcare"
                              className="ml-3  text-xl sm:text-2xl"
                            >
                              <p className="dark:text-white">
                                I am healthcare professional or a researcher
                              </p>
                            </label>
                          </div>
                          <div className="relative flex items-start">
                            <div className="flex items-center h-5">
                              <input
                                id="member"
                                name="patient"
                                type="checkbox"
                                className="focus:ring-green-500 h-4 h-4 sm:h-6 sm:w-6  text-green-600 border-gray-300 rounded"
                                {...register("patient", {
                                  required: false,
                                })}
                              />
                            </div>
                            <label
                              htmlFor="member"
                              className="ml-3 text-xl sm:text-2xl"
                            >
                              <p className="dark:text-white">
                                I am a patient or a member of the public
                              </p>
                            </label>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>

                  {/* <div className="sm:col-span-2">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={RECAPTCHA_KEY}
                      size="normal"
                      id="recaptcha-google"
                      onChange={() => setButtonDisabled(false)}
                    />
                  </div>*/}

                  <div className="text-right sm:col-span-2">
                    {/*{!buttonDisabled && (*/}
                    <button
                      type="submit"
                      // disabled={buttonDisabled}
                      className="inline-flex justify-center py-2 px-4 border border-black dark:border-white shadow-sm text-xl sm:text-2xl font-medium rounded-md text-black dark:text-white bg-grape-600 hover:bg-grape-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-grape-500"
                    >
                      Submit
                    </button>
                    {/*)}*/}
                  </div>
                </form>
                <div className="flex justify-end w-full mt-2">
                  {errorMessage && (
                    <div className="rounded-md bg-red-50 p-4">
                      <div className="-mx-2 -my-2 flex justify-end">
                        <button
                          type="button"
                          onClick={() => setErrorMessage(false)}
                          className="inline-flex rounded-md p-1 focus:outline-none "
                        >
                          <span className="sr-only">Close</span>
                          <XIcon className="h-4 w-4" aria-hidden="true" />
                        </button>
                      </div>
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <XCircleIcon
                            className="h-5 w-5 text-red-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3">
                          <h3 className="text-xl font-medium text-red-800">
                            There was an error trying to send your message.
                          </h3>
                          <div className="mt-1 text-sm text-red-800">
                            <ul role="list" className="text-xl space-y-1">
                              <li>
                                Please check your form data and try again.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {showSuccessMessage && (
                  <>
                    <div
                      onClick={() => setShowSuccessMessage(false)}
                      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                      <div className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6 ">
                        <div>
                          <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-green-100">
                            <CheckIcon
                              className="h-12 w-12 text-green-600"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="mt-3 text-center sm:mt-5">
                            <h3 className="text-2xl leading-6 font-medium text-gray-900">
                              Message Sent!
                            </h3>
                            <div className="mt-2">
                              <p className="text-lg text-gray-500">
                                We have received your message and would like to
                                thank you for writing to us.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="mt-5 sm:mt-6">
                          <button
                            type="button"
                            onClick={() => setShowSuccessMessage(false)}
                            className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-400 text-base font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="fixed inset-0 z-40 bg-gray-500 bg-opacity-75"></div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Contact;
