import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useHotkeys } from "react-hotkeys-hook";
import { XIcon } from "@heroicons/react/outline";
import { EyeOffIcon } from "@heroicons/react/solid";
import { useBetween } from "use-between";

const useFormState = () => {
  const [open, setOpen] = useState(false);

  return {
    open,
    setOpen,
  };
};

const useSharedFormState = () => useBetween(useFormState);

const VisualOptions = () => {
  const { open, setOpen } = useSharedFormState();
  const [isShow, setIsShow] = useState(false);
  const [fontSize, setFontSize] = useState(100);

  let contrastText = [0, 0, 0];
  let textColorSliders = document.getElementsByClassName("rangeText");
  let bgColorSliders = document.getElementsByClassName("rangeBg");
  let links = document.querySelectorAll("a");

  const handleClick = () => {
    setIsShow(!isShow);
  };

  const resizeFont = (press) => {
    if (press === "increase") {
      document.querySelector("html").style.fontSize = `${fontSize + 10}%`;
      setFontSize(fontSize + 10);
    } else {
      document.querySelector("html").style.fontSize = `${fontSize - 10}%`;
      setFontSize(fontSize - 10);
    }
  };

  const Highlight = (press) => {
    if (press === "highlight") {
      links.forEach((a) => {
        a.style.backgroundColor = "#ffff00";
        a.style.color = "black";
      });
    }
  };

  const Reset = (press) => {
    if (press === "reset") {
      document.querySelector("html").removeAttribute("style");
      setIsShow(false);

      links.forEach((a) => {
        a.removeAttribute("style");
      });

      document
        .querySelectorAll("h1, h2, h3, h4, h5, h6, p, label")
        .forEach((tag) => tag.removeAttribute("style"));

      document.querySelector("html").classList.remove("accessible-font");
      contrastText = [0, 0, 0];
      for (var i = 0; i < 3; i++) {
        textColorSliders[i].value = 0;
      }
    }
  };

  const FontFamily = (press) => {
    if (press === "family") {
      if (
        document.querySelector("html").classList.contains("accessible-font") ===
        true
      ) {
        document.querySelector("html").classList.remove("accessible-font");
      } else {
        document.querySelector("html").classList.add("accessible-font");
      }
    }
  };

  const clamp = (val, min, max) => {
    return Math.min(Math.max(val, min), max);
  };

  const contrastChange = (target, array) => {
    let valueInc = target * 51;
    for (let i = 0; i < array.length; i++) {
      let newVal = clamp(parseInt(array[i]) + valueInc, 0, 255);
      array[i] = newVal;

      if (array == contrastText) {
        textColorSliders[i].value = newVal;
      }
    }
  };

  const contrastTextLighten = (press) => {
    if (press === "contrastTextLighten") {
      contrastChange(1, contrastText);
      document
        .querySelectorAll("h1, h2, h3, h4, h5, h6, p, label")
        .forEach((tag) => (tag.style.color = "rgb(" + contrastText + ")"));
    }
  };

  const contrastTextDarken = (press) => {
    if (press === "contrastTextDarken") {
      contrastChange(-1, contrastText);
      document
        .querySelectorAll("h1, h2, h3, h4, h5, h6, p, label")
        .forEach((tag) => (tag.style.color = "rgb(" + contrastText + ")"));
    }
  };

  const textInputValues = (i) => {
    contrastText[i] = textColorSliders[i]?.value;
    document
      .querySelectorAll("h1, h2, h3, h4, h5, h6, p, label")
      .forEach(
        (tag) => (tag.style.color = "rgb(" + contrastText.join(",") + ")")
      );
  };
  const textInputChange = () => {
    for (let i = 0; i < 3; i++) {
      textInputValues(i);
    }
  };
  document.addEventListener("input", textInputChange);

  // keys visual options slider

  useHotkeys("esc", () => setOpen(false));
  useHotkeys("Q", () => setOpen(true));
  useHotkeys("O", () => setIsShow(true));

  useHotkeys("A", () => {
    if (
      document.querySelector("html").classList.contains("accessible-font") ==
      true
    ) {
      document.querySelector("html").classList.remove("accessible-font");
    } else {
      document.querySelector("html").classList.add("accessible-font");
    }
  });

  useHotkeys("S", () => {
    document.querySelector("html").style.fontSize = `${fontSize + 10}%`;
    setFontSize(fontSize + 10);
  });

  useHotkeys("D", () => {
    document.querySelector("html").style.fontSize = `${fontSize - 10}%`;
    setFontSize(fontSize - 10);
  });

  useHotkeys("H", () => {
    if (links) {
      links.forEach((a) => {
        a.style.backgroundColor = "#ffff00";
        a.style.color = "black";
      });
    }
  });

  useHotkeys("F", () => {
    document.querySelector("html").removeAttribute("style");
    setIsShow(false);
    links.forEach((a) => {
      a.removeAttribute("style");
    });

    document
      .querySelectorAll("h1, h2, h3, h4, h5, h6, p, label")
      .forEach((tag) => tag.removeAttribute("style"));

    document.querySelector("html").classList.remove("accessible-font");

    contrastText = [0, 0, 0];
    for (let i = 0; i < 3; i++) {
      if (bgColorSliders[i] !== undefined) {
        textColorSliders[i].value = 0;
      }
    }
  });

  // end keys visual options slider

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden z-50"
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-sm">
                <div className="flex dark:bg-black h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-xl font-medium text-gray-900 dark:text-white">
                        Visual Accessibility Options
                      </Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative mt-6 flex-1 px-4 sm:px-6">
                    <div className=" flex justify-between mb-6 text-base">
                      <button
                        type="button"
                        id="reset"
                        accessKey="f"
                        onClick={() => {
                          Reset("reset");
                        }}
                        className="text-base  cursor-pointer border rounded-2xl border-2 border-apple-black border-solid bg-apple-black text-white py-1.5 px-3"
                      >
                        Reset Options (F)
                      </button>
                      <button
                        type="button"
                        id="closeAccessPanel"
                        onClick={() => setOpen(false)}
                        className="text-base border rounded-2xl border-2 border-apple-black border-solid bg-apple-black text-white py-1.5 px-3"
                      >
                        Close Options (esc)
                      </button>
                    </div>

                    <div className="button-group m-2 mb-4 text-xl">
                      <p className="mb-2 dark:text-white">More Readable Font</p>
                      <button
                        id="fontSwap"
                        accessKey="a"
                        onClick={() => {
                          FontFamily("family");
                        }}
                        className="text-base border rounded-2xl border-2 border-apple-black border-solid bg-apple-black text-white py-1.5 px-3"
                      >
                        Font Swap (A)
                      </button>
                    </div>
                    <div className="font-resize button-group m-2 mb-4 text-xl">
                      <p className="mb-2 dark:text-white">Font Resize</p>
                      <button
                        id="fontDecrease"
                        accessKey="s"
                        className="text-base border rounded-2xl border-2 border-apple-black border-solid bg-apple-black text-white py-1.5 px-3"
                        onClick={() => {
                          resizeFont("decrease");
                        }}
                      >
                        Decrease (S)
                      </button>
                      <button
                        id="fontIncrease"
                        accessKey="d"
                        className="text-base border rounded-2xl border-2 border-apple-black border-solid bg-apple-black text-white py-1.5 px-3 ml-2 "
                        onClick={() => {
                          resizeFont("increase");
                        }}
                      >
                        Increase (D)
                      </button>
                    </div>
                    <div className="button-group m-2 mb-4 text-xl">
                      <p className="mb-2 dark:text-white">Highlight Links</p>
                      <button
                        id="highlightLinks"
                        accessKey="h"
                        onClick={() => {
                          Highlight("highlight");
                        }}
                        className="text-base border rounded-2xl border-2 border-apple-black border-solid bg-apple-black text-white py-1.5 px-3"
                      >
                        Highlight Links (H)
                      </button>
                    </div>
                    <div className="button-group m-2 mb-4 text-xl">
                      <p className="dark:text-white mb-2">Advanced Options</p>

                      <button
                        type="button"
                        id="remove"
                        accessKey="g"
                        onClick={handleClick}
                        className="text-base cursor-pointer border rounded-2xl border-2 border-apple-black border-solid bg-apple-black text-white py-1.5 px-3"
                      >
                        Advanced (O)
                      </button>
                    </div>
                    {isShow && (
                      <div>
                        <div className="contrastTextChange button-group m-2 mb-4 mt-4 text-xl ">
                          <p className="mb-2 dark:text-white">
                            Text Contrast Change
                          </p>
                          <button
                            id="contrastTextLighten"
                            accessKey="z"
                            onClick={() => {
                              contrastTextLighten("contrastTextLighten");
                            }}
                            className="text-base border rounded-2xl border-2 border-apple-black border-solid bg-apple-black text-white py-1.5 px-3"
                          >
                            Lighten
                          </button>
                          <button
                            id="contrastTextDarken"
                            accessKey="x"
                            onClick={() => {
                              contrastTextDarken("contrastTextDarken");
                            }}
                            className="text-base border rounded-2xl border-2 border-apple-black border-solid bg-apple-black text-white py-1.5 px-3 ml-2"
                          >
                            Darken
                          </button>
                        </div>
                        <div className="rangeSliders m-2 dark:text-white text-xl">
                          <p>Red:</p>{" "}
                          <input
                            className="rangeText w-full"
                            type="range"
                            min="0"
                            max="255"
                            defaultValue="0"
                          />
                          <p>Green:</p>{" "}
                          <input
                            className="rangeText w-full"
                            type="range"
                            min="0"
                            max="255"
                            defaultValue="0"
                          />
                          <p>Blue:</p>{" "}
                          <input
                            className="rangeText w-full"
                            type="range"
                            min="0"
                            max="255"
                            defaultValue="0"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const OpenButton = () => {
  const { setOpen } = useBetween(useFormState);

  useEffect(() => {
    return () => {
      setOpen(false);
    };
  }, []);

  const onClick2 = () => {
    setOpen(true);
  };

  return (
    <button
      type="button"
      onClick={onClick2}
      className="inline-flex items-center lg:px-4 mt-1  px-3 py-1.5 lg:py-1.5 border border-gray-600 dark:border-white shadow-sm text-sm lg:text-xl font-medium rounded-md text-gray-600 dark:text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
    >
      <EyeOffIcon className="-ml-1 mr-0 h-5 w-5 lg:mr-3" aria-hidden="true" />
      <span className="hidden xl:block">Visual Accessibility Options (Q)</span>
    </button>
  );
};

export { OpenButton, VisualOptions };
