import React, { useState } from "react";

const PatientJourney = () => {
  const [current, setCurrent] = useState(1);

  const changeStatePrevious = () => {
    setCurrent(current - 1);
    console.log(current);
    console.log(current - 1);
  };
  const changeStateNext = () => {
    setCurrent(current + 1);
  };

  return (
    <>
      <div id="patient-journey" className="bg-white dark:bg-black">
        <div className="max-w-2xl sm:pt-40 pt-40 mx-auto px-4 sm:px-6  lg:max-w-7xl lg:px-8">
          <div className="w-full mx-auto ">
            <h2 className="text-5xl text-center font-bold tracking-tight text-black dark:text-white sm:text-8xl">
              The patient journey
            </h2>
            <div className="sm:mt-20 mt-10 space-y-16">
              <div className="text-black dark:text-white sm:text-3xl text-2xl">
                <p>
                  <span className="font-bold">
                    Being diagnosed with an inherited retinal disease (IRD)
                    takes years for most patients.
                  </span>{" "}
                  It usually starts with an optician noticing your sight
                  worsening, or signs of eye damage. This leads to many tests
                  and appointments. The aim is to work out which specific gene
                  is faulty. Then, the doctor can explain which disease you have
                  (the diagnosis). And, how your sight will change over time
                  (prognosis).
                </p>

                <div className="mt-10 sm:mt-20 text-gray-500">
                  <ul
                    role="list"
                    className="rounded-md  sm:px-2 lg:flex lg:space-x-4"
                  >
                    <li className="flex">
                      <div className="flex items-center">
                        <svg
                          className="block lg:hidden flex-shrink-0 sm:ml-0 ml-2 mr-2 sm:mr-2 w-2 md:h-4 md:w-3 lg:w-8 h-4 lg:h-full text-black dark:text-white"
                          viewBox="0 0 24 44"
                          preserveAspectRatio="none"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                        >
                          <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <button
                          className={
                            current == 1
                              ? "bg-white border text-black text-xl sm:text-2xl "
                              : "text-black dark:text-white text-xl sm:text-2xl"
                          }
                          type="button"
                          onClick={() => setCurrent(1)}
                        >
                          Patient notices sight worsening
                        </button>
                      </div>
                    </li>
                    <li className="flex">
                      <div className="flex items-center">
                        <svg
                          className="flex-shrink-0 sm:ml-0 ml-2 mr-2 sm:mr-2 w-2 md:h-4 md:w-3 lg:w-8 h-4 lg:h-full text-black dark:text-white"
                          viewBox="0 0 24 44"
                          preserveAspectRatio="none"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                        >
                          <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <button
                          className={
                            current == 2
                              ? "bg-white border text-black text-xl sm:text-2xl"
                              : "font-medium text-black dark:text-white text-xl  sm:text-2xl"
                          }
                          type="button"
                          onClick={() => setCurrent(2)}
                        >
                          Optician visit
                        </button>
                      </div>
                    </li>
                    <li className="flex">
                      <div className="flex items-center">
                        <svg
                          className="flex-shrink-0 w-2 sm:ml-0 ml-2 mr-2 sm:mr-2 md:h-4 md:w-3 lg:w-8 h-4 lg:h-full text-black dark:text-white"
                          viewBox="0 0 24 44"
                          preserveAspectRatio="none"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                        >
                          <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <button
                          className={
                            current == 3
                              ? "bg-white border text-black text-xl sm:text-2xl"
                              : "font-medium text-black dark:text-white text-xl sm:text-2xl"
                          }
                          type="button"
                          onClick={() => setCurrent(3)}
                        >
                          Specialist referral
                        </button>
                      </div>
                    </li>
                    <li className="flex">
                      <div className="flex items-center">
                        <svg
                          className="flex-shrink-0 sm:ml-0 ml-2 mr-2 sm:mr-2 w-2 md:h-4 md:w-3 lg:w-8 h-4 lg:h-full text-black dark:text-white"
                          viewBox="0 0 24 44"
                          preserveAspectRatio="none"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                        >
                          <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <button
                          className={
                            current == 4
                              ? "bg-white border text-black text-xl  sm:text-2xl "
                              : "text-2xl font-medium text-black dark:text-white text-xl sm:text-2xl "
                          }
                          type="button"
                          onClick={() => setCurrent(4)}
                        >
                          Eye scans
                        </button>
                      </div>
                    </li>
                    <li className="flex">
                      <div className="flex items-center">
                        <svg
                          className="flex-shrink-0 w-2 sm:ml-0 ml-2 mr-2 sm:mr-2 md:h-4 md:w-3 lg:w-8 h-4 lg:h-full text-black dark:text-white"
                          viewBox="0 0 24 44"
                          preserveAspectRatio="none"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                        >
                          <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <button
                          className={
                            current == 5
                              ? "bg-white border text-black text-xl sm:text-2xl "
                              : "text-2xl font-medium text-black dark:text-white text-xl sm:text-2xl "
                          }
                          type="button"
                          onClick={() => setCurrent(5)}
                        >
                          Genetic testing
                        </button>
                      </div>
                    </li>
                    <li className="flex">
                      <div className="flex items-center">
                        <svg
                          className="flex-shrink-0 w-2 sm:ml-0 ml-2 mr-2 sm:mr-2 md:h-4 md:w-3 lg:w-8 h-4 lg:h-full text-black dark:text-white"
                          viewBox="0 0 24 44"
                          preserveAspectRatio="none"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                        >
                          <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <button
                          className={
                            current == 6
                              ? "bg-white border text-black text-xl  sm:text-2xl "
                              : "font-medium text-black dark:text-white text-xl sm:text-2xl "
                          }
                          type="button"
                          onClick={() => setCurrent(6)}
                        >
                          Diagnosis
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="flex">
                  <button
                    className={
                      current == 1
                        ? "top-0 bottom-20 border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0 hidden"
                        : "top-0 bottom-20 border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
                    }
                    type="button"
                    onClick={changeStatePrevious}
                  >
                    <span className="hidden lg:block carousel-control-prev-icon w-16 h-16 sm:w-20 sm:h-20 bg-black rounded-full text-white inline-block bg-no-repeat"></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <div className="max-w-full mx-auto py-12 px-4 sm:pb-16 sm:px-6 lg:px-8 sm:block">
                    {/*<div>*/}
                    {current === 1 ? (
                      <div className="space-y-4">
                        <div className="dark:bg-black">
                          <img
                            src="images/patient.png"
                            alt=""
                            className="sm:h-96 sm:w-full md:w-full shadow-lg rounded-lg"
                          />
                        </div>
                        <div className="text-center  bottom-0">
                          <p className="text-2xl text-black bg-white dark:text-white dark:bg-black">
                            1. A young person may notice their sight worsening.
                          </p>
                        </div>
                      </div>
                    ) : null}
                    {current === 2 ? (
                      <div className="space-y-4">
                        <div className="dark:bg-black">
                          <img
                            src="images/optician.png"
                            alt=""
                            className="lg:object-cover sm:h-96 sm:w-full shadow-lg rounded-lg"
                          />
                        </div>
                        <div className="text-center bottom-0">
                          <p className="text-2xl  text-black bg-white dark:text-white dark:bg-black">
                            2. A visit to an optician or an ophthalmologist may
                            suggest there is something wrong.
                          </p>
                        </div>
                      </div>
                    ) : null}
                    {current === 3 ? (
                      <div className=" space-y-4">
                        <div className="dark:bg-black">
                          <img
                            src="images/specialist.png"
                            alt=""
                            className="lg:object-cover sm:h-96 sm:w-full shadow-lg rounded-lg"
                          />
                        </div>
                        <div className="text-center bottom-0">
                          <p className="text-2xl  text-black bg-white dark:text-white dark:bg-black">
                            3. This could lead to a referral to a specialist
                            centre.
                          </p>
                        </div>
                      </div>
                    ) : null}
                    {current === 4 ? (
                      <div className="space-y-4">
                        <div className="dark:bg-black">
                          <img
                            src="images/eye_scans.gif"
                            alt=""
                            className="lg:object-cover sm:h-96 sm:w-full shadow-lg rounded-lg"
                          />
                        </div>
                        <div className="text-center bottom-0">
                          <p className="text-2xl text-black bg-white dark:text-white dark:bg-black">
                            4. During this appointment detailed eye scans will
                            be taken.
                          </p>
                        </div>
                      </div>
                    ) : null}
                    {current === 5 ? (
                      <div className="space-y-4">
                        <div className="dark:bg-black">
                          <img
                            src="images/genetic_testing.png"
                            alt=""
                            className="lg:object-cover sm:h-96 sm:w-full shadow-lg rounded-lg"
                          />
                        </div>
                        <div className="text-center bottom-0">
                          <p className="text-2xl  text-black bg-white dark:text-white dark:bg-black">
                            5. Based on these scans, a genetic test may be
                            ordered.
                          </p>
                        </div>
                      </div>
                    ) : null}
                    {current === 6 ? (
                      <div className="space-y-4">
                        <div className="dark:bg-black">
                          <img
                            src="images/genetic_diagnosis.png"
                            alt=""
                            className="lg:object-cover sm:h-96 sm:w-11/12 shadow-lg rounded-lg"
                          />
                        </div>
                        <div className="text-center bottom-0">
                          <p className="text-2xl  text-black bg-white dark:text-white dark:bg-black">
                            6. Eventually a genetic diagnosis may be identified.
                          </p>
                        </div>
                      </div>
                    ) : null}
                    {/*</div>*/}
                  </div>
                  <button
                    className={
                      current == 6
                        ? "relative top-0 bottom-0 border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0 hidden"
                        : "relative top-0 bottom-0 border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
                    }
                    type="button"
                    onClick={changeStateNext}
                  >
                    <span className="hidden lg:block carousel-control-next-icon  w-16 h-16 sm:w-20 sm:h-20 bg-black rounded-full text-white  inline-block bg-no-repeat"></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>

                <p className="mt-2 pb-6">
                  This process is hard for patients living far from eye
                  hospitals. It is even more stressful if you do not understand
                  why your sight is worsening. There are over 10,000 people in
                  the UK with undiagnosed IRD. Being diagnosed will always be
                  challenging, but could be easier with some improvements to the
                  healthcare system.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientJourney;
