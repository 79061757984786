import React from "react";

const OurStory = () => {
  const people = [
    {
      name: "Professor Michel Michaelides",
      role: (
        <ul className="list-disc">
          <li>Professor of Ophthalmology, UCL</li>
          <li>Consultant Ophthalmologist, Moorfields Eye Hospital</li>
        </ul>
      ),
      imageUrl: "michaelides.png",
      link: "https://profmichaelides.com/about/",
    },
    {
      name: "Professor Kaoru Fujinami",
      role: (
        <ul className="list-disc">
          <li>
            Honorary Consultant Ophthalmic Surgeon & Clinical Geneticist, Tokyo
            Medical Centre
          </li>
          <li>Honorary Professor of Ophthalmology, UCL</li>
          <li>Honorary Consultant Ophthalmologist, Moorfields Eye Hospital</li>
        </ul>
      ),
      imageUrl: "fujinami.png",
      link: "https://www.fujinamik.com/cv",
    },
    {
      name: "Dr Konstantinos Balaskas",
      role: (
        <ul className="list-disc">
          <li>Associate Professor of Ophthalmology, UCL</li>
          <li>
            Director, Moorfields Ophthalmic Reading Centre & Clinical AI Lab
          </li>
          <li>Consultant Ophthalmologist, Moorfields Eye Hospital</li>
        </ul>
      ),
      imageUrl: "balaskas.png",
      link: "https://www.linkedin.com/in/konstantinos-balaskas-216a09111/?originalSubdomain=uk",
    },

    {
      name: "Professor Susan Downes",
      role: (
        <ul className="list-disc">
          <li>Professor of Ophthalmology, Oxford University</li>
          <li>Consultant Ophthalmologist, Oxford Eye Hospital</li>
        </ul>
      ),
      imageUrl: "downes.png",
      link: "https://www.ndcn.ox.ac.uk/team/susan-downes",
    },
    {
      name: "Dr Mital Shah",
      role: (
        <ul className="list-disc">
          <li>Ophthalmology Registrar, Oxford Eye Hospital</li>
          <li>Honorary Clinical Research Fellow, Oxford Eye Hospital</li>
        </ul>
      ),
      imageUrl: "shah.png",
      link: "https://www.linkedin.com/in/mital-shah/?originalSubdomain=uk",
    },
    {
      name: "Dr Savita Madhusudhan",
      role: (
        <ul className="list-disc">
          <li>
            Consultant Ophthalmologist, Royal Liverpool University Hospital
          </li>
        </ul>
      ),
      imageUrl: "savita.jpg",
      link: "https://www.nhs.uk/profiles/consultant/6090932",
    },

    {
      name: "Jennifer Furman",
      role: (
        <ul className="list-disc">
          <li>
            I am the project manager for Eye2Gene. I work at the UCL
            Translational Research Office.
          </li>
        </ul>
      ),
      imageUrl: "Jennifer.png",
      link: "https://www.linkedin.com/in/jennifer-furman-757421231/",
    },

    {
      name: "Dr Dayyanah Sumodhee",
      role: (
        <ul className="list-disc">
          <li>
            I am a health psychologist working at Moorfields Eye Hospital. I am
            passionate about helping people understand and manage their health
            better.
          </li>
        </ul>
      ),
      imageUrl: "Dayyanah.jpeg",
      link: "https://www.linkedin.com/in/dr-dayyanah-sumodhee/",
    },
    {
      name: "Dr Ismail Moghul",
      role: (
        <ul className="list-disc">
          <li>
            I am the chief technology officer at Phenopolis Ltd where I lead the
            software development team
          </li>
        </ul>
      ),
      imageUrl: "Ismail.png",
      link: "https://www.linkedin.com/in/ismail-moghul/",
    },
    {
      name: "Stephen Archer",
      role: (
        <ul className="list-disc">
          <li>
            I am an engineer and project manager. I am pleased to be involved in
            the Eye2Gene project as my son is affected by an Inherited Retinal
            Disease.
          </li>
        </ul>
      ),
      imageUrl: "Archer.png",
    },
  ];
  return (
    <>
      <div id="our-story" className="bg-white dark:bg-black sm:pt-40 pt-40">
        <div className="max-w-2xl mx-auto  lg:max-w-7xl lg:px-8">
          {/* Details section */}
          <section aria-labelledby="details-heading">
            <div className="flex flex-col items-center ">
              <h2
                id="details-heading"
                className="text-5xl font-bold tracking-tight text-black dark:text-white sm:text-8xl"
              >
                Meet Nikolas and William
              </h2>
              <p className="mt-10 sm:text-3xl text-2xl text-black dark:text-white">
                Hi! We're Nikolas and William. We are artificial intelligence
                scientists leading the Eye2Gene project funded by a{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://fundingawards.nihr.ac.uk/award/AI_AWARD02488"
                >
                  National Institute of Health Research (NIHR) AI award
                </a>
                .
              </p>
            </div>

            <div className="mt-16 grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:gap-x-8">
              <div>
                <div className="w-full aspect-w-3 aspect-h-2 rounded-lg overflow-hidden">
                  <img
                    src="images/nikolas.png"
                    alt="Nikolas Pontikos."
                    className="w-full h-full object-center object-cover"
                  />
                </div>
                <h2 className="dark:text-white pt-6 text-3xl font-bold">
                  Dr Nikolas Pontikos
                </h2>
                <p className="mt-8 text-2xl text-black dark:text-white">
                  I am the lead for Eye2Gene. My interest in IRDs came from
                  analysing DNA of patient affected by IRDs using computer
                  algorithms. My background is in computer science. I run a
                  research team based at UCL and Moorfields Eye Hospital. The
                  aim of my team is to make healthcare more personal and
                  effective by using AI to better understand patient data.
                </p>
              </div>
              <div>
                <div className="w-full aspect-w-3 aspect-h-2 rounded-lg overflow-hidden">
                  <img
                    src="images/william-cat.jpeg"
                    alt="Dr William Woof"
                    className="w-full h-full object-center object-cover"
                  />
                </div>
                <h2 className="dark:text-white pt-6 text-3xl font-bold">
                  Dr William Woof
                </h2>
                <p className="mt-8 text-2xl text-black dark:text-white">
                  My interest in IRD started with a diagnosis at 18. Early on, I
                  involved myself in patient support groups. I went on to study
                  maths and AI at university. This sparked my interest in using
                  technology to help IRD patients like myself.
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="bg-white dark:bg-black">
        <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:pt-24">
          <div className="space-y-12">
            {/*<div className="space-y-5 sm:space-y-4 md:max-w-2xl lg:max-w-7xl xl:max-w-none">*/}
            <p className="sm:text-3xl text-2xl text-black dark:text-white">
              But we won't be doing it alone! We work with experienced doctors
              who specialise in IRDs. They manage the medical aspect of our
              research. Their role begins with the sourcing and labelling of
              patient data. Then, they will ensure that Eye2Gene provides
              medically accurate advice. And finally, they will advise on how
              Eye2Gene can be integrated into the healthcare system.
            </p>
            {/*</div>*/}
            <ul
              role="list"
              className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8"
            >
              {people.map((person) => (
                <li key={person.name}>
                  <div className="space-y-4">
                    <div className="text-center m-0 aspect-w-3 aspect-h-2">
                      <img
                        id="person"
                        className="object-cover h-full w-full shadow-lg rounded-lg"
                        src={`images/${person.imageUrl}`}
                        alt=""
                      />
                    </div>
                    <div className="leading-6 dark:text-white font-medium space-y-1">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={person.link}
                        className="text-3xl text-blue-500 font-bold "
                      >
                        {person.name}
                      </a>
                      <div className="text-2xl ml-4 dark:text-white">
                        {person.role}
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurStory;
