import React from "react";

const AI = () => (
  <div
    id="artificial-intelligence"
    className="relative bg-white dark:bg-black sm:pt-40 pt-40"
  >
    <h2 className="text-5xl mt-6 mb-16 text-center font-bold tracking-tight text-black dark:text-white sm:text-8xl">
      Artificial Intelligence
    </h2>
    <div className="h-56 bg-white dark:bg-black sm:h-72 lg:absolute lg:left-0 lg:h-full lg:w-1/2">
      <img
        className="w-full h-full object-cover"
        src="images/AI-final.png"
        alt=""
      />
    </div>
    <div className="relative max-w-7xl mx-auto px-4 py-8 sm:py-12 sm:px-6 lg:py-16">
      <div className="max-w-2xl mx-auto lg:max-w-none lg:mr-0 lg:ml-auto lg:w-1/2 lg:pl-10">
        <p className="mt-6 sm:text-3xl text-2xl text-black dark:text-white">
          <span className="font-bold">
            Artificial intelligence (AI) is a computer program built to perform
            a specific task. It gets better at that task the more it does it.{" "}
          </span>
          This ability to learn can solve problems in medical research.
        </p>
        <p className="mt-6  sm:text-3xl text-2xl text-black dark:text-white">
          Doctors diagnose IRD by looking at eye scans. Some diseases cause
          specific patterns of damage to the eye, which doctors recognise to
          work out which disease you have. Being able to understand scans like
          this requires many years of experience. The most experienced doctors
          are called consultants. There are only 50 in the UK specialising in
          IRD. This limits the number of patients they can see, often following
          a delay.
        </p>
        <p className="mt-6 sm:text-3xl text-2xl text-black dark:text-white">
          This has sparked interest in using AI to interpret medical images.
          Previous research has shown AI can identify eye disease using this
          information.
        </p>
      </div>
    </div>
  </div>
);

export default AI;
