import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./index.css";
import "flowbite";
import "tw-elements";
import App from "./App";
import Publications from "./components/Publications";
import { ThemeProvider } from "./components/themeContext";
import reportWebVitals from "./reportWebVitals";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

const root = document.getElementById("root");

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider>
        <div className="bg-white dark:bg-black transition-all">
          <main>
            <div className="absolute w-full right-0 top-0">
              <Navbar />
            </div>
            <Routes>
              <Route path="/" element={<App />} />
              <Route path="/about" element={<Navigate to="/" replace />} />
              <Route path="*" element={<Navigate to="/" replace />} />
              <Route path="/publications" element={<Publications />} />
            </Routes>
            <Footer />
          </main>
        </div>
      </ThemeProvider>
    </Router>
  </React.StrictMode>,
  root
);

reportWebVitals();
