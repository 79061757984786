import React from "react";
const posts = [
  {
    id: 1,
    description:
      "Before starting, we need permission to collect patient data. This is to make sure our research is fair, responsible, and ethical. We will apply to the NHS Health Research Authority.",

    imageUrl: "record_card.svg",
  },
  {
    id: 2,
    description:
      "Next, we will source the de-identified patient data and eye scans from four eye hospitals: Moorfields; Oxford; Royal Liverpool; and Tokyo Medical Centre.",

    imageUrl: "hospital_card.svg",
  },
  {
    id: 3,
    description:
      "Eye doctors will help review the scans. They will confirm which inherited retinal disease each patient has and highlight signs of eye damage.",

    imageUrl: "label_card.png",
  },
  {
    id: 4,
    description:
      "We can now build the Eye2Gene system. An AI algorithm looks at the labelled scans. It will learn to recognise damage from inherited retinal disease. And, how to work out which disease caused it.",

    imageUrl: "learning_card.svg",
  },
  {
    id: 5,
    description:
      "Next, Eye2Gene will try to copy the human experts. It will look at brand new, unlabelled eye scans. Then, it will predict which inherited retinal disease they show. We tell Eye2Gene if was correct, and the process repeats until Eye2Gene can reliably identify IRD.",

    imageUrl: "testing_card.svg",
  },
  {
    id: 6,
    description:
      "Finally, we will send Eye2Gene to the UK regulator for medical devices. Namely, the Medicines and Healthcare products Regulatory Agency. If approved, Eye2Gene can be used in the healthcare system.",

    imageUrl: "deployment_card.png",
  },
];
const Eye2Gene = () => (
  <div
    id="eye2gene"
    className="relative bg-white dark:bg-black sm:pt-40 pt-40 pb-20 px-4 sm:px-6 lg:pb-28 lg:px-8"
  >
    <div className="absolute inset-0">
      <div className="bg-white dark:bg-black  h-1/3 sm:h-2/3" />
    </div>
    <div className="relative max-w-7xl mx-auto">
      <div>
        <h2 className="text-5xl text-center tracking-tight font-bold text-black dark:text-white sm:text-8xl">
          Eye2Gene
        </h2>
        <p className="mt-3  mx-auto sm:text-3xl text-2xl text-black dark:text-white sm:mt-16">
          Eye2Gene is a research project based at University College London and Moorfields Eye Hospital.{" "}
          <span className="font-bold">
            Our aim is to train artificial intelligence on retinal scans to predict which inherited retinal disease the patient has, and specifically which gene is likely to be affected.
          Once validated, Eye2Gene can help make decisions about patient care.
          </span>
        </p>
      </div>
      <div className="mt-16 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
        {posts.map((post) => (
          <div
            key={post.id}
            className="flex flex-col rounded-lg shadow-lg overflow-hidden"
          >
            <div className="flex-shrink-0">
              <img
                className="h-48 w-full object-cover"
                src={`images/${post.imageUrl}`}
                alt=""
              />
            </div>
            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
              <div className="flex-1">
                <div className="block mt-2">
                  <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xl font-medium border-2 border-black text-black">
                    {post.id}
                  </span>
                  <p className="mt-3 text-xl text-black">{post.description}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <p className="dark:text-white mb-2 mt-8 max-w-md mx-auto sm:text-3xl text-2xl md:mt-8 md:text-3xl md:max-w-full">
        You can try a demo of the Eye2Gene app{" "}
        <a
          href="https://app.eye2gene.com"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 underline underline-offset-4 font-bold "
        >
          here.
        </a>
      </p>
      <p className="dark:text-white mb-2  max-w-md mx-auto sm:text-3xl text-2xl  md:text-3xl md:max-w-full">
        You can download an information sheet for your patients{" "}
        <a
          href="Eye2Gene-patient-information-sheet.pdf"
          download="Eye2Gene patient information sheet"
          className="text-blue-600 underline underline-offset-4 font-bold "
        >
          here.
        </a>
      </p>
      <p className="dark:text-white max-w-md mx-auto text-2xl sm:text-3xl md:text-3xl md:max-w-full">
        Please contact us using the form <a href="#contact-us">below</a>, if you
        would like to set up an account.
      </p>
    </div>
  </div>
);

export default Eye2Gene;
