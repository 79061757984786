import React from "react";

const Header = () => (
  <div id="theEye" className="bg-black">
    <div className="relative sm:h-screen h-full">
      <div className="absolute inset-0">
        <img
          className="w-full sm:mt-12 mt-40 h-full sm:object-cover object-cover"
          src="images/OCTscan.png"
          alt=""
        />
        <div
          className="absolute inset-0  mix-blend-multiply"
          aria-hidden="true"
        />
      </div>
      <div className="relative mx-auto py-48 px-4 sm:py-48 sm:px-6 lg:px-8">
        <h1 className="sm:text-6xl text-5xl text-center font-bold tracking-tight text-white md:text-7xl lg:text-8xl">
          This is your eye
        </h1>
      </div>
    </div>
  </div>
);

export default Header;
